import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {
  getGEMSPreSaleContract,
  getPreSaleContract,
  getLEIAPreSaleContract,
  getOlympusPreSaleContract,
  getNFAPreSaleContract,
} from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CuurentRound = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.PreSale;
  const incentivContractPresale = getPreSaleContract(tokenAddress, web3);

  const tokenAddressGEMS = Environment.GemsPreSale;
  const GEMSContractPresale = getGEMSPreSaleContract(tokenAddressGEMS, web3);

  const tokenAddressLEIA = Environment.LEIAPreSale;
  const LEIAContractPresale = getLEIAPreSaleContract(tokenAddressLEIA, web3);

  const tokenAddressOlympus = Environment.OlympusPreSale;
  const OlympusContractPresale = getOlympusPreSaleContract(
    tokenAddressOlympus,
    web3
  );

  const tokenAddressNFA = Environment.NFAPreSale;
  const NFAContractPresale = getNFAPreSaleContract(tokenAddressNFA, web3);

  // const project = localStorage.getItem("project");

  const cuurentRound = useCallback(
    async (proj) => {
      if (proj == "INCENTIV") {
        try {
          const buy = await incentivContractPresale.methods
            .getRoundCount()
            .call();
          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      } else if (proj == "OLYMPUS") {
        try {
          const buy = await OlympusContractPresale.methods
            .getRoundCount()
            .call();

          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      } else if (proj == "LEIA") {
        try {
          const buy = await LEIAContractPresale.methods.getRoundCount().call();

          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      } else if (proj == "NFA") {
        try {
          const buy = await NFAContractPresale.methods.getRoundCount().call();

          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      } else if (proj == "GEMS") {
        try {
          const buy = await GEMSContractPresale.methods.getRoundCount().call();

          return buy;
        } catch (error) {
          console.log("dlsflasdfjlasdf", error);
          throw error;
        }
      }
    },
    [LEIAContractPresale, incentivContractPresale, account, web3]
  );
  return { cuurentRound: cuurentRound };
};
export default CuurentRound;
