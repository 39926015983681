import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
} from "../../utils/contractHelpers";

import { useWeb3React } from "@web3-react/core";

const AllowClaimingHook = () => {
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const tokenAddress = environment.Claim;
  const contract = getClaimsContract(tokenAddress, web3);

  const tokenAddressLEIA = environment.LEIAClaim;
  const contractLEIA = getLEIAClaimsContract(tokenAddressLEIA, web3);

  const tokenAddressOlympus = environment.OlympusClaim;
  const contractOlympus = getOlympusClaimsContract(tokenAddressOlympus, web3);

  const tokenAddressNFA = environment.NFAClaim;
  const contractNFA = getNFAClaimsContract(tokenAddressNFA, web3);

  const project = localStorage.getItem("project");

  const allowClaimingHook = useCallback(
    async (bool, round, decesion) => {
      try {
        if (project == "INCENTIV") {
          if (bool) {
            const details = await contract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await contract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await contract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "OLYMPUS") {
          if (bool) {
            const details = await contractOlympus.methods
              .isEnabled(round)
              .call();
            return details;
          } else {
            const gas = await contractOlympus.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await contractOlympus.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "LEIA") {
          if (bool) {
            const details = await contractLEIA.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await contractLEIA.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await contractLEIA.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } else if (project == "NFA") {
          if (bool) {
            const details = await contractNFA.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await contractNFA.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await contractNFA.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        }
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { allowClaimingHook: allowClaimingHook };
};
export default AllowClaimingHook;
